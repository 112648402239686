import React, { PureComponent } from "react";

import styles from "../Team.module.scss";

export default class All extends PureComponent {
  render() {
    return (
      <div>
        <div className={styles.SplitContent}>
          <div className={styles.AllMembers}>
            <div
              onClick={() => {
                window.location.hash = "casi";
              }}
            >
              <div>Casi</div>
            </div>
            <div
              onClick={() => {
                window.location.hash = "shae";
              }}
            >
              <div>Shae</div>
            </div>
            <div
              onClick={() => {
                window.location.hash = "dani";
              }}
            >
              <div>Dani</div>
            </div>
          </div>
          <div className={styles.SplitContentText}>
            <div>
              <div>
                <h3 style={{ marginBottom: 0 }}>We are a curated group</h3>
                <h3 style={{ marginTop: 0 }}>of intuitive creators.</h3>
                <p>
                  We understand the power of a strong brand, and we are driven
                  to support the companies and organizations that are actively
                  working to make a difference in their communities. We are
                  world citizens using our creative talents to serve the good of
                  humanity as a whole.
                </p>
              </div>
            </div>
            <div>
              <a
                onClick={() => this.props.goTo("contact")}
                className={styles.ChangePageButton}
              >
                Get in Touch
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
