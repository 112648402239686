import React, { PureComponent } from "react";
import cx from "classnames";
import { navigate } from "gatsby";

import Navbar from "../Navbar";
import Footer from "../Footer";

import All from "./Teammates/All";
import Casi from "./Teammates/Casi";
import Dani from "./Teammates/Dani";
import Shae from "./Teammates/Shae";

import styles from "./Team.module.scss";

export default class Team extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      page: 0,
      trimmedHash: "",
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loaded: true,
      });
      const loaderElem = document.getElementById("loader");
      if (loaderElem) {
        loaderElem.parentNode.removeChild(loaderElem);
      }
    }, 0);

    window.addEventListener("hashchange", this.updatePageByHash);
    this.updatePageByHash();
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.updatePageByHash);
  }

  updatePageByHash = () => {
    const trimmedHash = window.location.hash.replace(/^\/|\/$/g, "");
    switch (trimmedHash) {
      case "#casi":
        this.setState({ page: 1, trimmedHash });
        break;
      case "#shae":
        this.setState({ page: 3, trimmedHash });
        break;
      case "#dani":
        this.setState({ page: 4, trimmedHash });
        break;
      default:
        this.setState({ page: 0, trimmedHash });
        break;
    }
  };

  render() {
    const { loaded, page, trimmedHash } = this.state;
    return (
      <div className={cx(styles.Team, { [styles.Loaded]: loaded })}>
        <Navbar
          goTo={(page) => {
            navigate(`/#${page}`);
          }}
          pathname={`team-${trimmedHash}`}
        />
        <div
          className={cx(styles.Page, { [styles.Active]: page === 0 && loaded })}
        >
          <All
            goTo={(page) => {
              navigate(`/#${page}`);
            }}
          />
        </div>
        <div
          className={cx(styles.Page, { [styles.Active]: page === 1 && loaded })}
        >
          <Casi />
        </div>
        <div
          className={cx(styles.Page, { [styles.Active]: page === 3 && loaded })}
        >
          <Shae />
        </div>
        <div
          className={cx(styles.Page, { [styles.Active]: page === 4 && loaded })}
        >
          <Dani />
        </div>
        <Footer
          goTo={(page) => {
            navigate(`/#${page}`);
          }}
        />
      </div>
    );
  }
}
