import React, { PureComponent, Fragment } from "react";

import "../styles/main.scss";

import Seo from "../components/Seo";
import Team from "../components/Team";

export default class TeamPage extends PureComponent {
  render() {
    return (
      <Fragment>
        <Seo title="Team" />
        <Team />
      </Fragment>
    );
  }
}
