import React, { PureComponent } from "react";

import styles from "../Team.module.scss";
import ShaeImg from "../../../img/team/shae.jpg";

export default class Shae extends PureComponent {
  render() {
    return (
      <div>
        <div className={styles.SplitContent}>
          <div>
            <img src={ShaeImg} />
          </div>
          <div className={styles.SplitContentText}>
            <div>
              <div>
                <h3>Shae Beaudoin</h3>
                <p>
                  Shae is a social media manager who challenges herself to push
                  social media marketing to the limits. She aims to adopt each
                  account's unique tone and voice in order to provide the best
                  experience possible to fans and followers. Her approach is
                  strictly organic—Shae works on genuine engagement, no
                  follow/unfollow or spam methods here. After careful hashtag
                  research, Shae will spend time building a relationship with
                  your specific target audience.
                </p>
                <p>
                  Shae is currently living in Orlando, FL though she has been
                  known to travel around the country pretty regularly. She
                  spends the majority of her time with her two dogs or visiting
                  the theme parks with her younger brother.
                </p>
                <div className={styles.MobileBack}>
                  <button onClick={() => (window.location.hash = "")}>
                    Back to Team
                  </button>
                </div>
                <p className={styles.MobileBumper} />
                <p className={styles.MobileBumper} />
              </div>
            </div>
            <div>
              <a
                onClick={() => (window.location.hash = "")}
                className={styles.ChangePageButton}
              >
                Back to Team
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
