import React, { PureComponent } from "react";

import styles from "../Team.module.scss";
import DaniImg from "../../../img/team/dani.jpg";

export default class Dani extends PureComponent {
  render() {
    return (
      <div>
        <div className={styles.SplitContent}>
          <div>
            <img src={DaniImg} />
          </div>
          <div className={styles.SplitContentText}>
            <div>
              <div>
                <h3>Dani</h3>
                <p>
                  Dani is a lifelong writer who approaches every project with a
                  creative ear and a light touch. With a belief in writing as a
                  healing modality, she works to help others find their voice.
                </p>
                <p>
                  With an MFA in Poetics, Dani studied rhetorical structure
                  alongside intuitive approaches to writing. She aims to bring
                  the full spectrum of her skills, imagination and experience to
                  every project.
                </p>
                <p>
                  You can find her in Denver, Colorado, where she plays music,
                  practices yoga and laughs into books on the bus.
                </p>
                <div className={styles.MobileBack}>
                  <button onClick={() => (window.location.hash = "")}>
                    Back to Team
                  </button>
                </div>
                <p className={styles.MobileBumper} />
                <p className={styles.MobileBumper} />
              </div>
            </div>
            <div>
              <a
                onClick={() => (window.location.hash = "")}
                className={styles.ChangePageButton}
              >
                Back to Team
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
