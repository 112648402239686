import React, { PureComponent } from "react";

import styles from "../Team.module.scss";
import CasiImg from "../../../img/team/casi.png";

export default class Casi extends PureComponent {
  render() {
    return (
      <div>
        <div className={styles.SplitContent}>
          <div>
            <img src={CasiImg} />
          </div>
          <div className={styles.SplitContentText}>
            <div>
              <div>
                <h3>Cassandra "Casi" Kelley</h3>
                <p>
                  Casi is the Founder and CEO of Casi Ari Design + Voice. As a
                  Designer, Writer, and Brand Consultant, she crafts bold visual
                  and written identities, and also consults with entrepreneurs
                  on brand growth.
                </p>
                <p>
                  Her designs are intended to offer a timeless, empathic
                  perspective to connect each brand to their audience with
                  intention, authenticity, and power. She believes in the
                  potential for brand work to show us the truths of ourselves
                  and what we can offer the world.
                </p>
                <p>
                  Casi calls the Dallas area home, but she loves to travel and
                  has been through much of Asia, with favorites in India and
                  Indonesia. When she's not designing, she can be found with a
                  notebook and pen in hand, writing inspirational prose (
                  <a
                    style={{ textDecoration: "underline" }}
                    href="https://www.instagram.com/casi.ari/"
                    target="_blank"
                  >
                    shared here
                  </a>
                  ).
                </p>
                <div className={styles.MobileBack}>
                  <button onClick={() => (window.location.hash = "")}>
                    Back to Team
                  </button>
                </div>
                <p className={styles.MobileBumper} />
                <p className={styles.MobileBumper} />
              </div>
            </div>
            <div>
              <a
                onClick={() => (window.location.hash = "")}
                className={styles.ChangePageButton}
              >
                Back to Team
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
